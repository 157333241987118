import messages from '../pages/summary/Summaries/messages';
import { default as drawerNameMessages } from '../components/DrawerName/messages';
import { default as priceMessages } from '../components/PriceWarning/messages';
import {
    useTrolleyPrice, useAccessoriesSubtotal, useFoamsSubtotal, useSubtotal
} from "../components/Subtotal/hooks";
import { default as subtotalMessages } from '../components/Subtotal/messages';
import {
    useAccessoriesInCart, useFoamsByDrawer, useConfiguredCart, useCartAccessoryIds, useCartFoamIds
} from "./cart";
import useCurrency from './useCurrency';
import { useSelectedTrolleyDetails } from "./useSelectedTrolley";
import { useShouldShowPrice } from './useShouldShowPrice';
import { default as tabsMessages } from '../pages/summary/Tabs/messages';
import { default as stepperMessages } from '../components/stepper/Stepper/messages';
import { formatPrice } from '../../model/price';
import { default as saveConfigButtonMessages } from '../pages/summary/SaveConfigButton/messages';
import { hubName } from '../../model/trolley';

const useSummaryJson = (intl) => {
    const trolleyDetails = useSelectedTrolleyDetails();
    const details = trolleyDetails.data || {};
    const trolleyPrice = useTrolleyPrice();
    const shouldShowPrice = useShouldShowPrice();
    const currency = useCurrency();
    const accessoriesInCart = useAccessoriesInCart();
    const accessoriesSubtotal = useAccessoriesSubtotal();
    const foamsByDrawer = useFoamsByDrawer();
    const foamsSubtotal = useFoamsSubtotal();
    const subtotal = useSubtotal();
    const cart = useConfiguredCart();
    const accessorySkus = useCartAccessoryIds();
    const foamSkus = useCartFoamIds();
    const accessories = {
        items: accessoriesInCart.map(accessory => ({
            name: accessory.name,
            sku: accessory.sku,
            price: formatPrice(intl, accessory.price, currency),
            thumbnail: accessory.thumbnail
        })),
        subtotal: formatPrice(intl, accessoriesSubtotal, currency)
    }
    const jsonDetails = {
        model: details.hub && hubName(intl, details.hub),
        color: details.color && details.color.toString(intl),
        drawers: details.drawers,
        subtotal: formatPrice(intl, trolleyPrice, currency)
    };
    const foams = {
        itemsByDrawer: foamsByDrawer.map(drawer =>
            drawer.map(foam => foam && ({
                name: foam.name,
                sku: foam.sku,
                price: formatPrice(intl, foam.price, currency),
                thumbnail: foam.thumbnail,
                size: foam.size.label
            }))),
        subtotal: formatPrice(intl, foamsSubtotal, currency)
    };
    const skus = [
        details.sku,
        ...accessorySkus,
        ...foamSkus
    ];

    const texts = {
        accessories: intl.formatMessage(tabsMessages.accessories),
        color: intl.formatMessage(messages.color),
        drawer: intl.formatMessage(drawerNameMessages.drawer),
        drawers: intl.formatMessage(messages.drawers),
        foams: intl.formatMessage(tabsMessages.fitAndGo),
        model: intl.formatMessage(messages.model),
        pricesWarning: intl.formatMessage(priceMessages.pricesWarning),
        subtotal: intl.formatMessage(subtotalMessages.subtotal),
        summary: intl.formatMessage(stepperMessages.step_summary),
        total: intl.formatMessage(saveConfigButtonMessages.total),
        trolley: intl.formatMessage(tabsMessages.trolley),
    };

    const summaryJson = {
        name: "Betms Configuration",
        pdfConfig: {
            accessories,
            trolley: jsonDetails,
            foams,
            previewImage: details.previewImage,
            shouldShowPrice: shouldShowPrice.data,
            total: formatPrice(intl, subtotal, currency),
            texts,
        },
        trolleyConfig: cart,
        skus
    };
    return summaryJson;
}

export default useSummaryJson;